<div>
  <p-sidebar
    [(visible)]="display"
    position="right"
    [showCloseIcon]="false"
    [baseZIndex]="10"
    [closeOnEscape]="false"
    [dismissible]="false"
    styleClass="p-sidebar-distribution"
  >
    <ng-template pTemplate="content">
      <div class="sidebar-header-distribution sidebar-header">
        <div>Distribution Lists</div>
        <div
          class="custom-close-icon"
          [ngClass]="{ disabled: uploadStatus === template.uploading }"
          (click)="closePanel()"
        >
          <i class="pi pi-times"></i>
        </div>
      </div>

      <div
        class="tab-upload-name-distribution"
        *ngIf="uploadStatus === template.uploaded"
      >
        <div class="title">Uploaded XLSX File</div>
        <div class="name-info">
          <div>
            <svg-icon class="custom-svg-icon" key="file" size="md"></svg-icon>
          </div>
          <div
            [pTooltip]="uploadingFile.uploadingFileName"
            tooltipPosition="bottom"
            class="name-file cursor-pointer"
          >
            {{ uploadingFile.uploadingFileName | shortFileName : 45 }}
          </div>
          <div [ngClass]="canEdit ? 'option-icon': 'd-none'">
            <svg-icon class="custom-svg-icon"
              key="dot3hoz"
              size="md"
              (click)="showContextMenu($event, distributionUrl)"
            ></svg-icon>
          </div>
          <app-menu
            #menu
            [optionList]="getMenuItemsForItem$ | async"
            [styleClass]="'menu-in-distribution'"
          ></app-menu>
        </div>
      </div>

      <div
        class="tab-info-distribution"
        *ngIf="uploadStatus !== template.nonPermission"
      >
        <div class="tab-info-item tab-basis-1">
          <div>
            <svg-icon class="custom-svg-icon" key="rig" size="md"></svg-icon>
          </div>
          <div class="item-name" [pTooltip]="rigName" tooltipPosition="bottom">
            {{ rigName }}
          </div>
        </div>

        <div class="tab-info-item tab-basis-1">
          <div>
            <svg-icon class="custom-svg-icon" key="project" size="md"></svg-icon>
          </div>
          <div
            class="item-name"
            [pTooltip]="rigProject"
            tooltipPosition="bottom"
          >
            {{ rigProject }}
          </div>
        </div>

        <div class="tab-info-item tab-basis-3">
          <div>
            <svg-icon class="custom-svg-icon" key="operator" size="md"></svg-icon>
          </div>
          <div
            class="item-name"
            [pTooltip]="rigOperator"
            tooltipPosition="bottom"
          >
            {{ rigOperator }}
          </div>
        </div>
      </div>

      <ng-container [ngSwitch]="uploadStatus">
        <ng-container
          *ngSwitchCase="template.nonPermission"
          [ngTemplateOutlet]="NonPermission"
        ></ng-container>
        <ng-container
          *ngSwitchCase="template.uploaded"
          [ngTemplateOutlet]="Uploaded"
        ></ng-container>
        <ng-container
          *ngSwitchCase="template.uploading"
          [ngTemplateOutlet]="Uploading"
        ></ng-container>
        <ng-container
          *ngSwitchCase="template.errorLoading"
          [ngTemplateOutlet]="errorLoading"
        ></ng-container>
        <ng-container
          *ngSwitchDefault
          [ngTemplateOutlet]="uploadYet"
        ></ng-container>
      </ng-container>

      <!-- Upload Yet -->
      <ng-template #uploadYet>
        <div class="upload-distribution">
          <svg-icon class="custom-svg-icon" key="upload" size="mdl"></svg-icon>
          <div class="title-h2">Document is not uploaded yet</div>
          <div class="des-text">XLSX format only</div>
          <div class="sub-text">or drag and drop here</div>
          <div id="uploadHidden">
            <input
              #uploadHidden
              type="file"
              class="file-upload"
              [ngClass]="{ 'file-upload-disabled': isViewer }"
              accept=".xlsx"
              [disabled]="isViewer || !canEdit"
              (change)="importDistrbutionList($event)"
            />
            <p-button
              #importDistrbutionListButton
              label="Upload XLSX Document"
              class="button-import custom-button--light"
              [disabled]="isViewer || !canEdit"
              (onClick)="openImportDistrbutionList()"
            ></p-button>
          </div>
        </div>
      </ng-template>

      <!-- Upload Error -->
      <ng-template #errorLoading>
        <div class="upload-distribution">
          <svg-icon class="custom-svg-icon" key="upload" size="mdl"></svg-icon>
          <div class="des-text">
            Document requires permission to read, please access to Help Center and create Report Issues.
          </div>
        </div>
      </ng-template>

      <!-- Uploading -->
      <ng-template #Uploading>
        <div class="uploading-distribution">
          <div class="title-h2">We are uploading the file...</div>
          <div class="des-text">
            {{
              uploadingFile.uploadingRemainingTime
                | number : "1.2-2"
                | timeUpload
            }}
          </div>
          <div class="uploading-progress-bar">
            <div class="uploading-percent">
              {{ uploadingFile.uploadingFilePercent }}%
            </div>
            <div class="uploading-progress">
              <p-progressBar
                [value]="uploadingFile.uploadingFilePercent"
                [showValue]="false"
                styleClass="progressUpload"
              ></p-progressBar>
            </div>
          </div>
          <div class="uploading-info">
            <div class="uploading-estimate">
              <div class="uploading-estimate-file">
                <div>
                  <svg-icon class="custom-svg-icon" key="file" size="md"></svg-icon>
                </div>
                <div class="uploading-estimate-fileName">
                  {{ uploadingFile.uploadingFileName }}
                </div>
              </div>
              <div class="uploading-estimate-size">
                {{ uploadingFile.uploadedBytes | formatFileSizePipe : false }}
                of
                {{
                  uploadingFile.uploadingFileSize | formatFileSizePipe : true
                }}
              </div>
            </div>
            <div class="uploading-btn">
              <p-button
                label="Cancel"
                class="button-import custom-button--dark d-none"
              ></p-button>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Uploaded -->
      <ng-template #Uploaded>
        <div id="uploadHidden" style="display: none">
          <input
            #uploadHidden
            type="file"
            class="file-upload"
            [ngClass]="{ 'file-upload-disabled': isViewer }"
            accept=".xlsx"
            [disabled]="isViewer || !canEdit"
            (change)="importDistrbutionList($event)"
          />
          <p-button
            #importDistrbutionListButton
            label="Upload XLSX Document"
            class="button-import custom-button--light"
            [disabled]="isViewer || !canEdit"
            (onClick)="openImportDistrbutionList()"
          ></p-button>
        </div>

        <!-- *ngIf="onlyTabError.length > 0" -->
        <ng-container *ngIf="false">
          <div class="tab-info-distribution text-error">
            {{ checkFileMessage }}
          </div>
        </ng-container>

        <p-tabView #tabViewDistribution styleClass="distribution-tab">
          <p-tabPanel *ngFor="let tab of excelData; first as isFirst">
            <ng-template pTemplate="header">
              <span *ngIf="isFirst" class="star-primary">
                <svg-icon class="custom-svg-icon" key="star" size="md"></svg-icon>
              </span>
              <span
                [pTooltip]="
                  tab.isError
                    ? 'Invalid data found'
                    : tab.isWarning
                    ? 'Warning data found'
                    : ''
                "
                tooltipPosition="bottom"
              >
                {{
                  tab.sheetName === "PRIMARY POINTS OF CONTACT"
                    ? "Primary Points of Contact"
                    : (tab.sheetName | titlecase)
                }}
                <span
                  *ngIf="tab.isError || tab.isWarning"
                  [ngClass]="{
                    'text-error': tab.isError,
                    'text-warning': !tab.isError && tab.isWarning
                  }"
                >
                  <svg-icon class="" key="exclamationMark" size="md"></svg-icon>
                </span>
              </span>
            </ng-template>
            <div class="distribution-tab-purpose">
              {{ tab.purpose }}
            </div>
            <p-table
              [value]="tab.data"
              styleClass="p-datatable-striped custom-table"
              [rowHover]="true"
              [scrollable]="true"
              scrollHeight="calc(100vh - 395px)"
            >
              <ng-template pTemplate="header">
                <tr style="height: 40px">
                  <th>Name</th>
                  <th>Company</th>
                  <th>Title</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th *ngIf="isFirst">Order</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-sheet>
                <tr style="height: 49px">
                  <ng-container
                    *ngIf="
                      sheet['FIRST NAME'] === undefined &&
                        sheet['LAST NAME'] === undefined;
                      then showNA;
                      else showData
                    "
                  ></ng-container>
                  <td
                    [pTooltip]="sheet['COMPANY'] | NA : 'No Data'"
                    tooltipPosition="bottom"
                    [ngClass]="{
                      'text-warning':
                        sheet.VALIDATION_CHECK.OPTIONAL.HALL_ID_ERROR
                    }"
                  >
                    {{ sheet["COMPANY"] | NA : "No Data" }}
                  </td>
                  <td
                    [pTooltip]="
                      sheet.VALIDATION_CHECK.OPTIONAL.TITLE_ERROR
                        ? optionalCheck.title
                        : sheet['TITLE']
                    "
                    tooltipPosition="bottom"
                    [ngClass]="{
                      'text-warning':
                        sheet.VALIDATION_CHECK.OPTIONAL.TITLE_ERROR
                    }"
                  >
                    {{ sheet["TITLE"] | NA : "No Data" }}
                  </td>
                  <td
                    class="td-feature"
                    [ngClass]="{
                      'text-error': sheet.VALIDATION_CHECK.MANDATORY.EMAIL_ERROR
                    }"
                    [pTooltip]="
                      sheet.VALIDATION_CHECK.MANDATORY.EMAIL_ERROR
                        ? sheet['EMAIL']
                          ? mandatoryCheck.email
                          : mandatoryCheck.emailBlank
                        : (sheet['EMAIL'] | NA : 'No Data')
                    "
                    tooltipPosition="bottom"
                  >
                    <div class="td-content email">
                      {{ sheet["EMAIL"] | NA : "No Data" }}
                    </div>
                    <div
                      class="td-icon"
                      (click)="copyText(sheet?.EMAIL)"
                      *ngIf="sheet.EMAIL"
                    >
                      <svg-icon class="custom-svg-icon" key="clipBoard" size="md"></svg-icon>
                    </div>
                  </td>
                  <td
                    class="td-feature"
                    [ngClass]="{
                      'text-error':
                        sheet.VALIDATION_CHECK.MANDATORY.PHONE_ERROR,
                      'text-warning':
                        sheet.VALIDATION_CHECK.OPTIONAL.PHONE_ERROR
                    }"
                    [pTooltip]="sheet.PHONE_HOVER"
                    tooltipPosition="bottom"
                  >
                    <div class="td-content phone">
                      {{ sheet["PHONE"] | NA : "No Data" }}
                    </div>
                    <div
                      (click)="copyText(sheet?.PHONE)"
                      class="td-icon"
                      *ngIf="sheet.PHONE"
                    >
                      <svg-icon class="custom-svg-icon" key="clipBoard" size="md"></svg-icon>
                    </div>
                  </td>
                  <td
                    *ngIf="isFirst"
                    class="td-feature"
                    [ngClass]="{
                      'text-error': sheet.VALIDATION_CHECK.MANDATORY.ORDER_ERROR
                    }"
                    [pTooltip]="
                      sheet.VALIDATION_CHECK.MANDATORY.ORDER_ERROR
                        ? sheet['ORDER']
                          ? mandatoryCheck.order
                          : mandatoryCheck.orderBlank
                        : sheet['ORDER']
                    "
                    tooltipPosition="bottom"
                  >
                    {{ sheet["ORDER"] | NA : "No Data" }}
                  </td>
                </tr>

                <!-- Template display each cell -->
                <ng-template #showData>
                  <td
                    class="td-feature"
                    [pTooltip]="sheet.NAME_HOVER"
                    tooltipPosition="bottom"
                  >
                    <span
                      *ngIf="sheet.VALIDATION_CHECK.OPTIONAL.FIRSTNAME_ERROR"
                      [ngClass]="isFirst ? 'text-error-icon' : 'text-warning-icon'"
                    >
                      <svg-icon class="custom-svg-icon" key="exclamationMark" size="md"></svg-icon>
                    </span>
                    <span
                      *ngIf="sheet.VALIDATION_CHECK.OPTIONAL.LASTNAME_ERROR"
                      [ngClass]="isFirst ? 'text-error-icon' : 'text-warning-icon'"
                    >
                      <svg-icon class="custom-svg-icon" key="exclamationMark" size="md"></svg-icon>
                    </span>
                    <span>
                      {{ sheet["FIRST NAME"] | NA : "" }}
                      {{ sheet["LAST NAME"] | NA : "" }}
                    </span>
                  </td>
                </ng-template>
                <ng-template #showNA>
                  <td
                    class="td-feature"
                    [ngClass]="isFirst ? 'text-error' : 'text-warning'"
                    [pTooltip]="
                      isFirst ? mandatoryCheck.name : optionalCheck.name
                    "
                    tooltipPosition="bottom"
                  >
                    {{ "" | NA : "No Data" }}
                  </td>
                </ng-template>
              </ng-template>
            </p-table>
          </p-tabPanel>
        </p-tabView>
        <div class="distribution-btn d-flex justify-content-end gap-2">
          <p-button
            [attr.data-cy]="'cancel-button'"
            label="Cancel"
            (onClick)="closePanel()"
            class="custom-p-button custom-p-button--primary"
          ></p-button>
          <p-button
            [attr.data-cy]="'reupload-button'"
            *ngIf="onlyTabError.length"
            label="Reupload"
            class="custom-p-button custom-p-button--secondary"
            (onClick)="dialogReupload()"
          ></p-button>
          <p-button
            [attr.data-cy]="'save-button'"
            label="Save"
            class="custom-p-button custom-p-button--secondary"
            (onClick)="saveDialog()"
            [disabled]="distributionUrl || uploadHidden.files?.length ? true : false"
          ></p-button>
        </div>
      </ng-template>

      <ng-template #NonPermission> Dont Have Enough Permission </ng-template>
    </ng-template>

    <!-- Loading -->
    <app-loading [isLoading]="isLoading"></app-loading>
  </p-sidebar>
</div>
