import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DialogType, OptionButtonAndCheckboxType, OptionButtonAndIdType, OptionButtonAndTextContent, OptionButtonType } from '../../type';
import { AppConstant } from '../../utilities/app.constant';
import { IDropdownSelection } from '../../interface/common';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() header: string = 'Header Confirm Dialog';

  @Input() haveCheckbox: boolean = false;
  @Input() checkboxLabel: string = 'Check Box Message';

  @Input() haveDialogMessage: boolean = true;
  @Input() dialogMessage: string  = 'Dialog Message';

  @Input() havePrimaryButton: boolean = true;
  @Input() primaryButtonLabel: string = 'Button 1';

  @Input() haveSecondaryButton: boolean = true;
  @Input() secondaryButtonLabel: string = 'Button 2';

  @Output() clickButtonEvent = new EventEmitter<OptionButtonType | OptionButtonAndIdType | OptionButtonAndTextContent | OptionButtonAndCheckboxType>();

  @Input() isValidPrimaryButton: boolean = true;

  // will by pass disable button primary
  @Input() byPassCheckbox: boolean = true;

  @Input() disablePrimaryButton: boolean = false;
  
  @Input() disableSecondaryButton: boolean = false;

  @Input() haveBoxSelection: boolean = false;
  
  @Input() dataSelection: any;

  @Input() haveSecondaryMessage: boolean = false;
  @Input() secondaryMessage: string  = 'Secondary Dialog Message';

  @Input() haveTertiaryMessage: boolean = false;
  @Input() tertiaryDialogMessage: string  = 'Tertiary Dialog Message';

  @Input() dialogMessageTemplate: string  = 'Select the template of the report:';
  @Input() dialogMessageLanguage: string  = 'Select the language of the report:';
          
  @Input() dataDropdownTemplate: IDropdownSelection[]  = AppConstant.ALERT_TEMPLATE;
  @Input() dataDropdownLanguage: IDropdownSelection[]  = AppConstant.LANGUAGE_TEMPLATE;
  @Input() isDisableDropdown: boolean = false;
  @Input() defaultTemplate: IDropdownSelection = this.dataDropdownTemplate[0];
  @Input() defaultLanguage: IDropdownSelection = this.dataDropdownLanguage[1];

  // Decice Align Button Group -- Default: Left
  @Input() alignCenterButton: boolean = false;

  @Input() styleDialog: DialogType = 'dark-dialog';

  // Configuration For Header have Icon
  @Input() isHeaderIcon: boolean = false;
  @Input() headerIcon: string = 'nominate';

  // Configuration Content is Textarea
  @Input() isHaveTextarea: boolean = false;

  @Input() textarePlaceHolder: string = 'Provide justification for nominating this Alert...';
  @Input() textareaSize: number = 6; // Default: 6 -> row number of Textarea

  isConfirmCheckbox: boolean = false;
  textareaContent: string = '';
  constructor() {}

  ngOnInit(): void {
    // Always Reset Checkbox to False when open new dialog
    this.isConfirmCheckbox = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // At least one change at header need to be rerender value of dialog --> This modifier for checkbox
    if (changes.header && changes.header.currentValue) {
      this.isConfirmCheckbox = false;
    }

    if (changes.header && changes.header.currentValue) {
      this.isConfirmCheckbox = false;
    }

    if (changes.dataDropdownTemplate && changes.dataDropdownTemplate.currentValue) {
      this.dataDropdownTemplate = changes.dataDropdownTemplate.currentValue;
      this.defaultTemplate = this.dataDropdownTemplate[0];
    }
  }

  onChangeTemplate(event: any) {
    this.dataDropdownTemplate.forEach((item: any) => {
      if (item.id === event.value.id) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    })
  }

  onChangeLanguage(event: any) {
    this.dataDropdownLanguage.forEach((item: any) => {
      if (item.id === event.value.id) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    })
  }

  onButtonClick(buttonType: OptionButtonType) {
    if (this.disableSecondaryButton === true && buttonType === 'cancel') return;
    if (this.disableSecondaryButton === false && buttonType === 'cancel') {
      this.clickButtonEvent.emit(buttonType); 
      return;
    }
    

    if (this.haveBoxSelection) {
      const isSelected = this.dataSelection.find(
        (mail: any) => mail.isSelected
      );
      this.clickButtonEvent.emit({
        buttonType: 'yes',
        dataSelected: isSelected,
        template: this.defaultTemplate
      });
    } else if (this.textareaContent) {
      this.clickButtonEvent.emit({
        buttonType: 'yes',
        textareaContent: this.textareaContent
      });
      this.textareaContent = ''
    }
    else if (this.byPassCheckbox) {
      this.clickButtonEvent.emit({
        buttonType: buttonType,
        isConfirmCheckbox: this.isConfirmCheckbox,
      });
    } else {
      this.clickButtonEvent.emit(buttonType);
    }

    this.isConfirmCheckbox = false;
  }

  onToggleCheckbox() {
    this.isConfirmCheckbox === true || this.byPassCheckbox ? 
      this.isValidPrimaryButton = true 
      : this.isValidPrimaryButton = false;
  }

  toggleClass(item: any) {
    if (!item.isSelected) {
      this.dataSelection.forEach((item: any) => (item.isSelected = false));
      item.isSelected = !item.isSelected;
      this.disablePrimaryButton = !this.dataSelection.some(
        (item: any) => item.isSelected
      );
      this.isValidPrimaryButton = true;  
    } else {
      item.isSelected = !item.isSelected;
      this.disablePrimaryButton = true;
      this.isValidPrimaryButton = false; 
    }
  }
}
