import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ElementRef, OnChanges, SimpleChanges, ViewEncapsulation, ViewContainerRef, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, finalize, map } from 'rxjs';
import { DetailVideoComponent } from 'src/app/pages/help-center/detail-video/detail-video.component';
import { AzureBlobService } from '../../services/azure-blob.service';
import { HelpCenterService } from 'src/app/pages/help-center/help-center.service';
import { AppHelper } from '../../utilities/app.helper';
import { UserInfoService } from '../../services/user-info.service';
import { ExpandImgComponent } from '../expand-img/expand-img.component';

@Component({
  selector: 'app-tutorial-detail',
  templateUrl: './tutorial-detail.component.html',
  styleUrls: ['./tutorial-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TutorialDetailComponent implements OnInit, OnChanges  {
  
  @Input() selectedItem: string = '';
  @Input() tutorialContent: any;
  @Input() isShow: boolean = false;

  @Output() showList: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('alertContainer', { read: ViewContainerRef }) container: any;
  
  loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private helpCenterService: HelpCenterService,
    public dialogService: DialogService,
    private el: ElementRef, 
    private userInfoService: UserInfoService,
    private blobService: AzureBlobService) {}

  ngOnInit() {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tutorialContent?.currentValue) {
      const render = changes.tutorialContent.currentValue;
      this.addEventContent(render);
    }
  }
  
  expandImg(event: any, link: string) {
    this.showImage(link);
  }

  expandVideo(event: any, videoId: string) {
    this.loadingData.next(true);
    this.helpCenterService.getVideoDetail(videoId)
      .pipe(map((response: any) => response.data), finalize(() => this.loadingData.next(false)))
      .subscribe({
        next: (video) => {
          this.loadVideo(video);
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  
  public showImage(link: string): void {
    const ref = this.dialogService.open(ExpandImgComponent, {
      header: 'Expand the image',
      width: '100vw',
      height: '90vh',
      showHeader: false,
      data: {
        srcBase64: link,
      },
      styleClass: 'hide-dialog',
    });

    ref.onClose.subscribe({
        next: (res: any) => {
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  addEventContent(htmlString: string) {
    this.tutorialContent = htmlString;

    setTimeout(() => {
      var ele = this.el.nativeElement.querySelectorAll('img');
      if (ele.length) {
        ele.forEach((img: any) => {
          img.classList.add('cursor-pointer');
          img.addEventListener('click', (event: any) => this.expandImg(event, img.getAttribute('src')));
        });
      }

      var textHeader = this.el.nativeElement.querySelectorAll('h1');
      if (textHeader.length) {
        textHeader.forEach((h1: any) => {
          h1.classList.add('text-header');
          if (h1.querySelectorAll('img').length > 0) {
            h1.classList.add('expand-image');
          }
        });
      }

      var textTitle = this.el.nativeElement.querySelectorAll('h2');
      textTitle.forEach((h2: any) => {
        h2.classList.add('text-title');
      });

      
      var videoEle = this.el.nativeElement.querySelectorAll('h3');
      for (let index = 0; index < videoEle.length; index++) {
        const h3 = videoEle[index];
        if (!h3.getAttribute('href')) {
          h3.classList.add('text-description');
          continue
        }
        h3.classList.add('cursor-pointer');
        h3.classList.add('text-content');
        h3.addEventListener('click', (event: any) => this.expandVideo(event, h3.getAttribute('href')));
      }

      var videoEle = this.el.nativeElement.querySelectorAll('p');
      videoEle.forEach((h3: any) => {
        h3.classList.add('text-description');
      });

    }, 0)
  }
    
  private loadVideo(video: any): void {
    const videoName = decodeURIComponent(
      AppHelper.StringFunctions.getFileName(video.url)
    );
    video.srcVideo = this.blobService.getVideoUrl(videoName);
    this.createComponent(video);
  }

  private createComponent(video: any): void {
    const componentRef = this.container.createComponent(DetailVideoComponent);
    componentRef!.instance.video = video;
    componentRef!.instance.display = true;
    componentRef!.instance.user = this.userInfoService.userSubject.getValue();
    componentRef!.instance.onDestroy.subscribe((event: any) => {
      if (event) video.views += 1;
      componentRef.destroy();
    });
  }

  getThumbnail(imageName: string): string {
    return this.blobService.getVideoThumbnailUrl(
      decodeURIComponent(imageName)
    );
  }

  replaceTags(html: string): string {
    const replacedHtml = html
      .replace(/<a(.*?)<\/a>/g, '<h3$1</h3>')
      .replace(/<img(.*?)<\/img>/g, '<p-image$1</p-image>');
    return replacedHtml;
  }

  goBack() {
    this.showList.emit(true);
  }
}
