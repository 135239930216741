/**
 * Contains environment-specific configuration values for the production environment.
 */
export const environment = {
  pipelineId: '1262',
  pipelineName: 'VRO-Dev-Backend',
  production: false,
  apiUrl: 'https://dev.vro-api.ienergy.halliburton.com',
  baseUrl: 'https://dev.vro-api.ienergy.halliburton.com',
  clientId: 'f635d4ad-70b1-4e9c-a7ef-a6cf0449b9b1',
  logoutUrl: 'https://dev.vro.ienergy.halliburton.com/login',
  redirectUri: 'https://dev.vro.ienergy.halliburton.com/home',
  authority:
    'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5',
  scopes: 'api://f635d4ad-70b1-4e9c-a7ef-a6cf0449b9b1/VRO.ALL',
  blobAccountName: 'hpmvrodev2scusstapp',
  sasToken:
    '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rtfx&se=2024-12-31T15:44:37Z&st=2024-08-06T07:44:37Z&spr=https&sig=I3zAoJU5OoqINGvwLJ8X5JRtRndMQPE0z%2FkMUJK6BXo%3D',
  CLOUD_ROLE_NAME: 'FE_DEV',
  AZURE_INSIGHTS_STRING:
    'InstrumentationKey=fe86c104-210a-4610-88b5-d6b1a7491f51;IngestionEndpoint=https://southcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/',
  POWER_BI_LINK: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=052efacb-f237-4de0-b7c8-b98aa5300822&ctid=b7be7686-6f97-4db7-9081-a23cf09a96b5'
};

// [production: boolean]        // Indicates if the application is in production mode

// API configuration
// [apiUrl: string]             // The base URL for API requests

// Base URL configuration
// [baseUrl: string]            // The base URL for the application

// Authentication and authorization configuration
// [logoutUrl: string]          // The URL to redirect to for logging out
// [redirectUri: string]        // The redirect URL after successful authentication
// [clientId: string]           // The client ID used for authentication
// [authority: string]          // The authority URL for authentication and authorization

// Azure Blob Storage configuration
// [blobAccountName: string]    // The name of the Azure Blob Storage account
// [sasToken: string]           // The shared access signature (SAS) token for accessing the Blob Storage account
