export const asWellCareLogo = {
  data: `<svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.4809 6.44739C14.5881 5.80447 15.1443 5.33325 15.7961 5.33325H26.8704C27.5222 5.33325 28.0785 5.80447 28.1856 6.44739L30.4079 19.7807C30.5433 20.5934 29.9166 21.3333 29.0927 21.3333H13.5739C12.75 21.3333 12.1233 20.5934 12.2587 19.7807L14.4809 6.44739Z"/>
  <path d="M11.6095 24.3905C11.8595 24.1405 12.1987 24 12.5523 24H30.1144C30.468 24 30.8071 24.1405 31.0572 24.3905L35.0572 28.3905C35.8972 29.2305 35.3023 30.6667 34.1144 30.6667H8.55231C7.36444 30.6667 6.76955 29.2305 7.6095 28.3905L11.6095 24.3905Z"/>
  <path d="M28.3675 33.3333H25.7102L26.5673 35.8797C27.0968 34.9798 27.6996 34.1283 28.3675 33.3333Z"/>
  <path d="M25.7109 37.5153C24.6126 39.8934 23.9998 42.5418 23.9998 45.3333C23.9998 47.3041 24.3053 49.2037 24.8714 50.9872L23.4358 53.3333H19.4347L16.3218 40.8977L17.3136 33.3333H24.3034L25.7109 37.5153Z"/>
  <path d="M25.4592 52.5812C25.5661 52.8348 25.6785 53.0855 25.7962 53.3333H24.999L25.4592 52.5812Z"/>
  <path d="M15.9689 33.3333L14.9668 40.9756L18.0602 53.3333H15.5163C14.9982 53.3333 14.5271 53.0332 14.308 52.5638L6.21897 35.2304C5.80644 34.3465 6.45171 33.3333 7.42721 33.3333H15.9689Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M32.0002 37.3334C30.5274 37.3334 29.3335 38.5273 29.3335 40.0001V56.0001C29.3335 57.4728 30.5274 58.6667 32.0002 58.6667H56.0002C57.4729 58.6667 58.6668 57.4728 58.6668 56.0001V40.0001C58.6668 38.5273 57.4729 37.3334 56.0002 37.3334H49.3335V36.0001C49.3335 35.2637 48.7365 34.6667 48.0002 34.6667H40.0002C39.2638 34.6667 38.6668 35.2637 38.6668 36.0001V37.3334H32.0002ZM41.3335 40.0001H46.6668V45.3334H52.0002V50.6667H46.6668V56.0001H41.3335V50.6667H36.0002V45.3334H41.3335V40.0001Z"/>
  </svg>
  
  `,
  name: 'wellCareLogo',
};
