import { NgModule } from '@angular/core';
import { StringCheckRiskLength } from './string-checkRiskLength.pipe';
import { StringShortFileNamePipe } from './string-shortFileName.pipe';
import { StringMaxLengthPipe } from './string-maxLength.pipe';
import { StringNaPipe } from './string-toNA.pipe';
import { StringContactMethodPipe } from './string-convertToContactMethod.pipe';
import { StringEventHierarchyPipe } from './string-convertToEventHierarchy.pipe';
import { StringRiskPipe } from './string-convertToRisk.pipe';
import { StringCustomTitleCasePipe } from './string-customTitleCase.pipe';
import { StringShowRangeSliderPipe } from './string-showRangeSlider.pipe';
import { StringNameEmailPipe } from './string-showNameEmail.pipe';
import { StringGetRolePipe } from './string-getRole.pipe';
import { StringHyphansCyPipe } from './string-hyphansCY.pipe';

@NgModule({
  declarations: [
    StringCheckRiskLength,
    StringShortFileNamePipe,
    StringMaxLengthPipe,
    StringNaPipe,
    StringContactMethodPipe,
    StringEventHierarchyPipe,
    StringRiskPipe,
    StringCustomTitleCasePipe,
    StringShowRangeSliderPipe,
    StringNameEmailPipe,
    StringGetRolePipe,
    StringHyphansCyPipe
  ],
  exports: [
    StringCheckRiskLength,
    StringShortFileNamePipe,
    StringMaxLengthPipe,
    StringNaPipe,
    StringContactMethodPipe,
    StringEventHierarchyPipe,
    StringRiskPipe,
    StringCustomTitleCasePipe,
    StringShowRangeSliderPipe,
    StringNameEmailPipe,
    StringGetRolePipe,
    StringHyphansCyPipe
  ],
})
export class StringPipeModule {}
