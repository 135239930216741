<section class="d-flex">
  <div *ngIf="_msalService.instance.getActiveAccount()?.localAccountId" class="icon-change-sidebar-item menu"
    (click)="changeIconMenu()">
    <svg-icon [key]="mennuClose ? 'close' : 'menucollapse'" size="md"></svg-icon>
  </div>

  <div class="header w-100" [ngClass]="{ 'header-login': hasRoute('login') }">
    <div class="left-header">
      <div class="left-header__title d-flex align-items-center" *ngIf="!hasRoute('login')" (click)="redirectTo('home')"
        [pTooltip]="APPLICATION_VERSION" tooltipPosition="bottom">
        <svg-icon key="wellCareLogo" size="xl"></svg-icon>
        <div class="name">{{ AppConstant.APPLICATION_NAME }}</div>
      </div>
      <div class="logo" *ngIf="hasRoute('login')">
        <img class="logo__img" srcset="../../../../assets/images/logo/Logo-dark-theme.png 2x" alt="Logo Halliburton." />
      </div>
    </div>

    <div class="right-header" *ngIf="_msalService.instance.getActiveAccount()?.localAccountId">

      <!-- show Switch Role -->
      <div *ngIf="isTester" class="right-header-item icon position-relative" style="width: 220px">
        <p-treeSelect [options]="roles" placeholder="Select Role" [(ngModel)]="selectedRole"
          (onNodeSelect)="onNodeSelect()" (onNodeExpand)="onNodeExpand($event)">
          <ng-template pTemplate="value">
            {{ selectedRole | getRole }}
          </ng-template>
        </p-treeSelect>
      </div>

      <!-- Show PowerBI Button -->
      <div class="right-header-item icon position-relative" (click)="redirectTo('powerbi')" 
        [pTooltip]="'Power BI Application'" tooltipPosition="bottom">
        <svg-icon key="powerbi" size="lg"></svg-icon>
      </div>

      <!-- Show Noti Center Button -->
      
      <div class="right-header-item icon position-relative" [ngClass]="{'active': notificationPanelExpanded }"
        (click)="onToggleNotificationPanel()" [pTooltip]="'Notification Center'" tooltipPosition="bottom">
        <!-- <svg-icon key="bell" size="xl"></svg-icon> -->
        <i class="pi pi-bell p-text-secondary" pBadge [value]="eventsCount > 99 ? '99+' : eventsCount + ''" severity="danger"></i>
        <!-- <div *ngIf="eventsCount > 0" class="absolute w-1rem h-1rem border-circle bg-red-700 
          flex flex-column justify-content-center align-items-center" style="top: 5px; left: 30px;">
          <span class="text-white" style="font-size: 10px;">{{ eventsCount > 99 ? '99+' : eventsCount}}</span>
        </div> -->
      </div>

      <!-- Show Distribution Templete Download Button -->
      <div class="right-header-item position-relative" (click)="downloadBlankDistributionList()" [pTooltip]="'Download Distribution Lists Template'" tooltipPosition="bottom">
        <svg-icon key="download" size="xl"></svg-icon>
      </div>

      <!-- Show User Traning Button -->
      <div class="right-header-item position-relative" [ngClass]="{'active': tutorialPanelExpanded }"
        (click)="onToggleTutorialPanel()" [pTooltip]="'Tutorials'" tooltipPosition="bottom">
        <svg-icon key="graduate" size="xl"></svg-icon>
      </div>

      <!-- Show User Panel Download Button -->
      <div class="right-header-item position-relative" [ngClass]="{ active: isDisplaySidebarUserInfomation }"
        (click)="userPanel()" [pTooltip]="'User Preferences'" tooltipPosition="bottom">
        <svg-icon key="singleuser" size="xl"></svg-icon>
      </div>
    </div>
  </div>
</section>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>