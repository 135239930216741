<div class="notification p-3 text-sm font-medium" style="height: calc(100vh - 40px); position: relative; top: -16px;">
  <!-- header -->
  <div>
    <div class="flex text-xl font-semibold mb-3">
      <span class="flex-1">Notification Center</span>
      <svg-icon (click)="closePanel()"
        class="cursor-pointer"
        key="close"
        size="md"
      ></svg-icon>
    </div>
    <div class="tabview-tab d-flex align-items-center mb-2 gap-3">
      <span
        (click)="tabClicked('alert')"
        class="tabview-item"
        [ngClass]="{ 'tabview-active': activeTab === 'alert'}">
        ALERTS 
      </span>
      <span 
        (click)="tabClicked('help_center')"
        class="tabview-item"
        [ngClass]="{ 'tabview-active': activeTab === 'help_center'}">
        HELP CENTER
      </span>
      <span system
        (click)="tabClicked('system')"
        class="tabview-item"
        [ngClass]="{ 'tabview-active': activeTab === 'system'}">
        SYSTEM
      </span>
      <span class="flex-1"></span>
      <span
        [ngClass]="{ 'disable-interact': (preventInteract | async) }"
        (click)="data.get(activeTab)?.results?.length && markAllEventsAsDeleted()"
        class="p-2 pl-0 cursor-pointer primary-color">
        Clear all
      </span>
    </div>
  </div>

  <!-- non-empty list/empty list/loading status/scoll -->
  <div #infiniteScroll (scroll)="scrollHandler($event)" class="overflow-y-auto" style="height: calc(100vh - 40px - 170px)">
    <ng-container *ngIf="data.get(activeTab)?.completedInitialLoad && !data.get(activeTab)?.results?.length">
      <div class="flex flex-column align-items-center p-3">
        <svg-icon key="bell" size="lg"></svg-icon>
        <span class="p-2"> No notifications yet </span>
      </div>
    </ng-container>
    <ng-container *ngFor="let event of data.get(activeTab)?.results; let i = index">
      <div (click)="markEventAsRead(event.id)"  [ngClass]="{'tabview-noti-item-read': event.is_read, 'tabview-noti-item': !event.is_read, 'my-3': true, 'p-3': true}">
        <div class="flex mb-1">
          <ng-container [ngSwitch]="event.type">
            <svg-icon
              *ngSwitchCase="'tutorial'"
              class="mr-2"
              key="graduate"
              size="sm"
            ></svg-icon>
            <svg-icon
              *ngSwitchCase="'alert'"
              class="mr-2"
              key="flash"
              size="sm"
            ></svg-icon>
            <svg-icon
              *ngSwitchCase="'mention_chat'"
              class="mr-2"
              key="mention"
              size="sm"
            ></svg-icon>
            <svg-icon
              *ngSwitchCase="'mention_video'"
              class="mr-2"
              key="mentionvideo"
              size="sm"
            ></svg-icon>
            <svg-icon
              *ngSwitchCase="'new_video'"
              class="mr-2"
              key="video"
              size="sm"
            ></svg-icon>
            <svg-icon
              *ngSwitchDefault
              class="mr-2"
              key="setting"
              size="sm"
            ></svg-icon>
          </ng-container>
          <span class="flex-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">
            {{ event.title }}
          </span>
          <span class="px-3">
            {{ event.createdAt | date : "dd-MMM-yyyy, HH:mm" }}
          </span>
          <svg-icon
            class="cursor-pointer hover:text-white-alpha-70"
            key="close"
            size="sm"
            (click)="markEventAsDeleted(event.id)"
          ></svg-icon>
        </div>
        <div class="flex mb-1"(click)="markEventAsRead(event.id)" style="color: var(--color-pallet-primary-02);">
          <span class="flex-1">{{ event.message }}</span>
          <a 
            [routerLink]="event.basePath" 
            [queryParams]="event.queryParams"
            [ngClass]="{'hidden': !event?.relative_path}"
            class="primary-color"
          >View</a>
        </div>
      </div>
    </ng-container>
    <div class="content-progress-bar-notification">
      <span class="loader" *ngIf="loadingData | async"></span>
    </div>
  </div>

  <div class="content-progress-bar" *ngIf="processingData | async">
    <span class="loader"></span>
    <span class="text-progressbar">Loading ...</span>
  </div>
</div>
