<p-dialog
  [(visible)]="displayAddWellboreDialog"
  [modal]="true"
  [style]="{ 'max-width': '480px', 'line-height': '12px' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
  [baseZIndex]="0"
>
  <ng-template pTemplate="header">
    <div>Add Wellbore</div>
    <div class="custom-close-icon" (click)="removeTheDraft()">
      <i class="pi pi-times"></i>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <div
      class="container add-input edit-form"
      *ngIf="!!formAddWellboreGroup"
      [formGroup]="formAddWellboreGroup"
    >
      <div class="row">
        <div class="col-12 mb-4">
          <app-multi-level-dropdown
            #multiDropdown
            [sourceData]="sourcesUpdate"
            [placeholder]="'Select data source'"
            [showClear]="true"
            scrollHeight="200px"
            [selectAllWhenChooseParent]="true"
            [isCustomTemplate]="true"
            seperateMark="()"
            [isCounterSelected]="false"
            label="Data Source"
            [isRequired]="true"
            [isDisabled]="formAddWellboreGroup.get('source')!.disabled"
            [multiType]="MULTI_LEVEL.SINGLE_SELECT.LOWEST_LEVEL.LABEL"
            [formGroup]="formAddWellboreGroup"
            [formControlNamePass]="fieldNameKeys.source"
            [messageErrors]="messageErrors"
            [customLabelString]="customString || 'Select data source'"
            [customClass]="'custom-p-drop'"
            (eventChange)="selectItem($event, 'source')"
          ></app-multi-level-dropdown>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('company')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Company
            <span
              [ngClass]="
                formAddWellboreGroup.get('company')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="companies"
              optionLabel="policyName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select company"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.company"
              (onChange)="selectItem($event, 'company')"
              [innerMsg]="messageErrors"
            >
            </p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('project')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Project
            <span
              [ngClass]="
                formAddWellboreGroup.get('project')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="projects"
              optionLabel="projectName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select project"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.project"
              (onChange)="selectItem($event, 'project')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('site')!.disabled ? 'label-disabled' : ''
            "
          >
            Site
            <span
              [ngClass]="
                formAddWellboreGroup.get('site')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="sites"
              optionLabel="siteName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select site"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.site"
              (onChange)="selectItem($event, 'site')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('well')!.disabled ? 'label-disabled' : ''
            "
          >
            Well
            <span
              [ngClass]="
                formAddWellboreGroup.get('well')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="wells"
              optionLabel="wellName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select well"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.well"
              (onChange)="selectItem($event, 'well')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('wellbore')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Wellbore
            <span
              [ngClass]="
                formAddWellboreGroup.get('wellbore')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="wellbores"
              optionLabel="wellboreName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select wellbore"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.wellbore"
              (onChange)="selectItem($event, 'wellbore')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('contractor')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Rig Contractor
            <span
              [ngClass]="
                formAddWellboreGroup.get('contractor')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="contractors"
              optionLabel="contractorName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select contractor"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.contractor"
              (onChange)="selectItem($event, 'contractor')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>

        <div class="col-12">
          <div
            class="add-input-title"
            [ngClass]="
              formAddWellboreGroup.get('assignRig')!.disabled
                ? 'label-disabled'
                : ''
            "
          >
            Assign Rig
            <span
              [ngClass]="
                formAddWellboreGroup.get('assignRig')!.disabled
                  ? 'label-disabled'
                  : 'p-error'
              "
            >
              *</span
            >
          </div>
          <div class="w-100">
            <p-dropdown
              [resetFilterOnHide]="true"
              [options]="assignRigs"
              optionLabel="rigName"
              [filter]="true"
              [showClear]="true"
              placeholder="Select rig"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.assignRig"
              (onChange)="selectItem($event, 'assignRig')"
              [innerMsg]="messageErrors"
            ></p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (onClick)="removeTheDraft()"
        class="custom-p-button custom-p-button--primary"
        ></p-button>
      <p-button
        [attr.data-cy]="'add-button'"
        label="Add"
        class="custom-p-button custom-p-button--secondary"
        (onClick)="addNewWellbore($event)"
        [disabled]="!formAddWellboreGroup.valid || isViewer"
        [ngClass]="
          formAddWellboreGroup.status === 'INVALID' || isViewer
          ? 'custom-p-button custom-p-button--disable'
          : ''
          "
      ></p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</p-dialog>
