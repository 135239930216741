import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NofiticationItemComponent } from './nofitication-item/nofitication-item.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { ShareModule } from '../../shared/shared.module';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { primeNgModules } from './import/primeNG';

const declarations = [NotificationListComponent, NofiticationItemComponent];

const imports: any = [
  ShareModule,
  CommonModule,
  FormsModule,
  ...primeNgModules
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [NotificationListComponent, NofiticationItemComponent],
  providers: [],
})
export class NotificationModule { }
