import { AppHelper } from 'src/app/shared/utilities/app.helper';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { NotificationService } from 'src/app/shared/services/notification.service';
import { passingExtendInfoService } from 'src/app/shared/services/passingExtendInfo.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-extendInfo',
  templateUrl: './extendInfo.component.html',
  styleUrls: ['./extendInfo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendInfoComponent implements OnInit {
  passingData: any = {
    clientX: 0,
    clientY: 0,
    primaryContact: [],
  };
  isEmptyData: boolean = false;
  isShowPanelWhenLoading: boolean = true;
  @Input() userInfo: any[] = [];

  @Input() clientX: number = 0;
  @Input() clientY: number = 0;

  get _clientX() {
    return this.clientX;
  }
  get _clientY() {
    return this.clientY;
  }

  constructor(
    private _passingDataService: passingExtendInfoService,
    private _notificationService: NotificationService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._passingDataService.currentApprovalStageMessage.subscribe(
      (data: any) => {
        if (data?.primaryContact?.length !== 0) {
          this.passingData = data;
          this.isShowPanelWhenLoading =
            this.passingData.primaryContact === 'Loading' ? false : true;
          this.isEmptyData =
            typeof this.passingData.primaryContact === 'string' ? true : false;
        }
        this._cd.detectChanges();
      }
    );
  }

  // (click)="makeCall('+84 929087540')"
  makeCall(phoneNumber: string): void {
    window.location.href = `tel:${phoneNumber}`;
  }

  // (click)="makeCallMsTeams('ABC@gmail.com')"
  makeCallMsTeams(phoneNumber: string, isSameDomain: boolean): void {
    if (!isSameDomain) {
      return;
    }
    const teamsUrl = `https://teams.microsoft.com/l/call/0/0?users=${phoneNumber}`;
    window.open(teamsUrl, '_blank');
  }

  // (click)="makeMailMsOutlook('thanh.nguyencong@halliburton.com', 'One', 'Two')"
  makeMailMsOutlook(
    emailAddress: string,
    subjectMail: string,
    bodyContent: string
  ) {
    const outlookUrl = `https://outlook.office.com/mail/deeplink/compose?to=${encodeURIComponent(
      emailAddress
    )}&subject=${encodeURIComponent(subjectMail)}&body=${encodeURIComponent(
      bodyContent
    )}`;
    // Use Outlook web app if Outlook app is not installed
    window.open(outlookUrl, '_blank');
  }

  // Checking Same Domain
  checkDomain(emailAddressLogin: string, emailAddressCompare: string): boolean {
    if (emailAddressLogin && emailAddressCompare) {
      let emailAddressLoginDomain = 'halliburton.com';
      let emailAddressCompareDomain = emailAddressCompare.split('@')[1];
      return emailAddressLoginDomain.toLocaleLowerCase() ===
        emailAddressCompareDomain.toLocaleLowerCase()
        ? true
        : false;
    } else {
      return false;
    }
  }
  // copy text
  copyText(copyText: string) {
    navigator.clipboard.writeText(copyText);
    this._notificationService.setMessage({
      type: AppConstant.MESSAGE_TYPE.SUCCESS,
      header: 'Copy clipboard !',
      content: `Copied: ${copyText} successfully`,
    });
  }

  filterPrimaryContact(listDataContact: any[]) {
    if (listDataContact && listDataContact.length) {
      // let listTypeContact_C = listDataContact.filter((contact) => {
      //   return contact.ORDER.charAt(0) === 'C';
      // });

      listDataContact = listDataContact.map((contact: any) => {
        return {
          ...contact,
          PHONE: AppHelper.UtileFunctions.replaceAllCharacter(
            contact.PHONE,
            '-',
            ''
          ).trim(),
        };
      });

      let take3Sort = [...listDataContact];
      take3Sort.sort(function (a, b) {
        var x = a.ORDER.toLowerCase();
        var y = b.ORDER.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return take3Sort;
      // return take3Sort.length === 0 ? listDataContact : take3Sort.slice(0, 3);
    } else {
      return listDataContact;
    }
  }
}
