import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';

@Component({
  selector: 'app-comment-video',
  templateUrl: './comment-video.component.html',
  styleUrls: ['./comment-video.component.scss'],
})
export class CommonVideoComponent implements OnInit {
  @Input() comment: any;
  @Input() userId: string = '';
  @Input() avatarUserUrl: string = '';  
  @Input() isAdmin: boolean = false;
  @Input() isViewer: boolean = false;
  @Output() menuSelected: EventEmitter<any> = new EventEmitter<any>();
  isMenuShown: boolean = false;
  items: any[] = [];

  constructor(private _azureBlobService: AzureBlobService) {}

  ngOnInit(): void {
    this.items = [
      {
        items: [
          {
            label: 'Delete',
            icon: 'pi pi-trash',
            disabled: this.isViewer,
            command: () => {
              this.menuSelected.emit({
                type: 'Delete',
                comment: this.comment,
              });
            },
          },
        ],
      },
    ];
  }

  public transformDate(date: string): string {
    return AppHelper.StringFunctions.descriptionDate(date);
  }

  public onMenuShow(): void {
    this.isMenuShown = true;
  }

  public onMenuHide(): void {
    this.isMenuShown = false;
  }
}
