import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { UserInfoService } from '../../services/user-info.service';
import { AppConstant } from '../../utilities/app.constant';
import { InterventionService } from '../../services/intervention.service';
import { TutorialService } from '../../services/tutorial.service';
import { CollapseLayoutService } from '../../services/collapseLayout.service';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['../../../../assets/styles/share/_sidebar.scss'],
})
export class SidebarComponent implements OnInit {
  public VRO_TABs: any[] = [];
  
  event$: any;
  currentPath: string = '';
  @Input() expandMenu$: boolean = false;

  constructor(
    private _router: Router,
    private _userInfoService: UserInfoService,
    private _interventionService: InterventionService,
    private _tutorialService: TutorialService,
    private _collapseLayoutService: CollapseLayoutService,
    private _homeService: HomeService
  ) {}

  ngOnInit() {
    this.getCurrentUrl().subscribe((url: string) => {
      this.currentPath = url === '/' ? 'home' : url;

      this._userInfoService.userSubject$.subscribe((userResponse: any) => {
        const user = userResponse || null;
        if (user && user.id && user.role) {
          if (user.role === 'Engineer') {
            this.VRO_TABs = [
              {
                title: 'Home',
                svg: 'home',
                sizeIcon: 'md',
                isActive: false,
                path: 'home',
              },
              {
                title: 'Alerts Management',
                svg: 'alert',
                sizeIcon: 'md',
                isActive: false,
                path: 'alerts/alerts-management',
              },
              {
                title: 'Help Center',
                svg: 'helpCenter',
                sizeIcon: 'md',
                isActive: false,
                path: 'help-center',
              },
            ];

            this.activeModuleTracking();
          } else if (user.role === AppConstant.ROLES.MANAGER.label) {
            this.VRO_TABs = [
              {
                title: 'Home',
                svg: 'home',
                sizeIcon: 'md',
                isActive: false,
                path: 'home',
              },
              {
                title: 'Alerts Management',
                svg: 'alert',
                sizeIcon: 'md',
                isActive: false,
                path: 'alerts/alerts-management',
              },
              {
                title: 'Team Management',
                svg: 'user',
                sizeIcon: 'md',
                isActive: false,
                path: 'user/team-management',
              },
              {
                title: 'Help Center',
                svg: 'helpCenter',
                sizeIcon: 'md',
                isActive: false,
                path: 'help-center',
              },
            ];

            this.activeModuleTracking();
          } else if (
            user.role === AppConstant.ROLES.ADMIN.label ||
            user.role === AppConstant.ROLES.VIEWER.label
          ) {
            this.VRO_TABs = [
              {
                title: 'Home',
                svg: 'home',
                sizeIcon: 'md',
                isActive: false,
                path: 'home',
              },
              {
                title: 'Alerts Management',
                svg: 'alert',
                sizeIcon: 'md',
                isActive: false,
                path: 'alerts/alerts-management',
              },
              {
                title: 'Rig Management',
                svg: 'rig',
                sizeIcon: 'md',
                isActive: false,
                path: 'rig/rig-management',
              },
              {
                title: 'Team Management',
                svg: 'user',
                sizeIcon: 'md',
                isActive: false,
                path: 'user/team-management',
              },
              {
                title: 'Mailbox Management',
                svg: 'mailbox',
                sizeIcon: 'md',
                isActive: false,
                path: 'mailbox/mailbox-management',
              },
              {
                title: 'Help Center',
                svg: 'helpCenter',
                sizeIcon: 'md',
                isActive: false,
                path: 'help-center',
              },
            ];

            this.activeModuleTracking();
          }
        } else {
          // In case Error --> Render Only Home to prevent break UI
          this.VRO_TABs = [
            {
              title: 'Home',
              svg: 'home',
              sizeIcon: 'md',
              isActive: false,
              path: 'home',
            },
          ];
          this.activeModuleTracking();
        }
        
        this._tutorialService.setCurrentPathSubject(this.currentPath);
        this._tutorialService.setUserTutorialSubject(this.VRO_TABs);
      });

    });
  }

  activeModuleTracking() {
    this.VRO_TABs = this.VRO_TABs.map((el) => {
      return this.currentPath.includes(el.path)
        ? { ...el, isActive: true }
        : el;
    });
  }
  activateClass(subModule: any) {
    this.VRO_TABs = this.VRO_TABs.map((el) => {
      let rs = { ...el };
      rs.isActive = false;
      if (subModule.title === el.title) {
        rs.isActive = true;
      }
      return rs;
    });
  }

  onTab(path: string) {
    if (path.includes('home')) {
      this._interventionService.resetLogFilter();
      // Set Default Filter -- Without CONSTANTS
      this._homeService.setPayloadFilterDefault();
      this._collapseLayoutService.updateCollapseLayout(true);
    }
    this._router.navigate([path.toLocaleLowerCase()])
  }

  hasRoute(route: string) {
    return this._router.url.includes(route);
  }

  getCurrentUrl(): Observable<string> {
    return this._router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
  }

  ngOnDestroy() {
    this.event$?.unsubscribe();
  }
}
