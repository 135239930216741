import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { tap } from 'rxjs';
import { UserInfoService } from '../services/user-info.service';
import { AppConstant } from '../utilities/app.constant';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _userInfoService: UserInfoService,
    private _msalService: MsalService  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let loginID = this._msalService.instance.getActiveAccount()?.localAccountId;
    const currentRole = this._userInfoService.userSubject.getValue()?.role;

    switch (route?.routeConfig?.path) {
      case 'mailbox/mailbox-management':
      case 'rig/rig-management':
        if (currentRole) {
          return (
            currentRole === AppConstant.ROLES.ADMIN.label ||
            currentRole === AppConstant.ROLES.VIEWER.label
          );
        }
        return this._userInfoService.apiAuthGuardAdmin(loginID).pipe(
          tap((allowed) => {
            if (!allowed) {
              this._router.navigate(['/home']);
            }
          })
        );
      case 'team-management':
      case 'team-management/:userId':
        if (currentRole) {
          return (
            currentRole === AppConstant.ROLES.ADMIN.label ||
            currentRole === AppConstant.ROLES.MANAGER.label ||
            currentRole === AppConstant.ROLES.VIEWER.label
          );
        }
        return this._userInfoService.apiAuthGuardAdminOrManager(loginID).pipe(
          tap((allowed) => {
            if (!allowed) this._router.navigate(['/home']);
          })
        );
      default:
        if (currentRole) {
          return (
            currentRole === AppConstant.ROLES.ADMIN.label ||
            currentRole === AppConstant.ROLES.MANAGER.label ||
            currentRole === AppConstant.ROLES.VIEWER.label
          );
        }
        return this._userInfoService.apiAuthGuardAdminOrManager(loginID).pipe(
          tap((allowed) => {
            if (!allowed) this._router.navigate(['/home']);
          })
        );
    }
  }

}
