import { AlertDetailComponent } from './pages/alert/alert-detail/alert-detail.component';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateGuard } from './shared/guards/CanActivateGuard';
import { ErrorComponent } from './pages/error/error.component';
// import { HomeProjectListComponent } from './pages/home/home-project-list/home-project-list.component';
// import { HomeRigItemComponent } from './pages/home/home-rig-item/home-rig-item.component';
// import { HomeRigListComponent } from './pages/home/home-rig-list/home-rig-list.component';
// import { AlertComponent } from './pages/intervention/intervention.component';
// import { LoginComponent } from './pages/login/login.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
// import { UserRoleComponent } from './pages/user/user-role/user-role.component';
// import { UserTeamManagementComponent } from './pages/user/user-team-management/user-team-management.component';
import { AuthGuard } from './shared/guards/AuthGuard';
// import { RigManagementComponent } from './pages/rig/rig-management/rig-management.component';
// import { HelpCenterComponent } from './pages/help-center/help-center.component';
// import { MailboxManagementComponent } from './pages/mailbox/mailbox-management/mailbox-management.component';
// import { AlertManagementComponent } from './pages/alert/alert-management/alert-management.component';
// import { TestComponent } from './pages/test/test.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    title: 'WellCare - Login',
  },
  {
    path: 'home',
    // component: HomeProjectListComponent,
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    title: 'WellCare - Home Page',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  // {
  //   path: 'home/rig/:id',
  //   component: AlertComponent,
  //   title: 'WellCare - Alert',
  //   canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  // },
  // {
  //   path: 'home/rig/:id/alert/:alertId',
  //   component: AlertComponent,
  //   title: 'WellCare - Edit Alert',
  //   canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  // },
  // {
  //   path: 'home/rig-list/:id',
  //   component: HomeRigListComponent,
  //   title: 'WellCare - Rig List',
  //   canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  // },
  // {
  //   path: 'home/rig-journal/:id/project/:projectId',
  //   component: HomeRigItemComponent,
  //   title: 'WellCare - Rig Item',
  //   canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  // },
  // {
  //   path: 'home/alert-rig/:rigJournalId',
  //   component: AlertDetailComponent,
  //   title: 'WellCare - Alert Detail',
  //   canActivate: [CanActivateGuard, MsalGuard],
  // },
  {
    path: 'rig/rig-management',
    loadChildren: () => import('./pages/rig/rig.module').then(m => m.RigModule),
    title: 'WellCare - Rig Management',
    canActivate: [CanActivateGuard, MsalGuard, AuthGuard], // <================ Must have MSAL code
  },
  {
    path: 'mailbox/mailbox-management',
    loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailBoxModule),
    title: 'WellCare - Mailbox Management',
    canActivate: [CanActivateGuard, MsalGuard, AuthGuard], // <================ Must have MSAL code
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
    canActivate: [CanActivateGuard, MsalGuard, AuthGuard],
  },
  {
    path: 'help-center',
    loadChildren: () => import('./pages/help-center/help-center.module').then(m => m.HelpVideoModule),
    title: 'WellCare - Help Center',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  // {
  //   path: 'help-center/video/:videoId',
  //   component: HelpCenterComponent,
  //   title: 'WellCare - Help Center',
  //   canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  // },
  {
    path: 'alerts/alerts-management',
    loadChildren: () => import('./pages/alert/alert.module').then(m => m.AlertModule),
    title: 'WellCare - Alert Management',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'dynamic-page',
    loadChildren: () => import('./pages/dynamic/dynamic.module').then(m => m.DynamicModule)
  },
  {
    path: '**',
    component: ErrorComponent,
    title: 'WellCare - Not Found',
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
