import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { asAddProject } from './svg/addProject';
import { asAdmin } from './svg/admin';
import { asPDF } from './svg/pdf';
import { asBell } from './svg/bell';
import { asChecker } from './svg/checker';
import { asClipBoard } from './svg/clipboard';
import { asClose } from './svg/close';
import { asContact } from './svg/contact';
import { asCritial } from './svg/critical';
import { asDecision } from './svg/decisionIcon';
import { asDone } from './svg/done';
import { asDot3Hoz } from './svg/dot3Hoz';
import { asEdit } from './svg/edit';
import { asFile } from './svg/file';
import { asHelper } from './svg/helper';
import { asHierarchy } from './svg/hierarchy';
import { asHome } from './svg/home';
import { asHourGlass } from './svg/hourGlass';
import { asIOneSheet } from './svg/ionesheet';
import { asLight } from './svg/light';
import { asLink } from './svg/link';
import { asLogoHall } from './svg/logoHall';
import { asWarning } from './svg/warning';
import { asMenuCollapse } from './svg/menuCollapse';
import { asOperator } from './svg/operator';
import { asPhone } from './svg/phone';
import { asProject } from './svg/project';
import { asProtocol } from './svg/protocol';
import { asReadEnvelope } from './svg/readEnvelope';
import { asRegion } from './svg/rergion';
import { asReset } from './svg/reset';
import { asRig } from './svg/rig';
import { asRigSidebar } from './svg/rigSibar';
import { asRigSite } from './svg/rigSite';
import { asRouting } from './svg/routing';
import { asSetting } from './svg/setting';
import { asStar } from './svg/star';
import { asStick } from './svg/stick';
import { asTime } from './svg/time';
import { asUnReadEnvelope } from './svg/unReadEnvelope';
import { asUpLoad } from './svg/uploadFile';
import { asUseFlag } from './svg/usaFlag';
import { asUser } from './svg/user';
import { asWell } from './svg/well';
import { asDownload } from './svg/download';
import { asMailbox } from './svg/mailbox';
import { asWellCareLogo } from './svg/wellCareLogo';
import { asSignOut } from './svg/signOut';
import { asAlert } from './svg/alert';
import { asLL } from './svg/LL';
import { asAlerts } from './svg/alerts';
import { asNominate } from './svg/nominate';
import { noData } from './svg/noData';
import { asPowerBI } from './svg/powerBI';
import { asSingleUser } from './svg/singleUser';
import { asField } from './svg/field';
import { asOffice } from './svg/office';
import { asExclamationMark } from './svg/exclamationMark';
import { asChatBubble } from './svg/chatBubble';
import { asFileVideo } from './svg/fileVideo';
import { asFlash } from './svg/flash';
import { asHelpCenter } from './svg/help-center';
import { asGraduate } from './svg/graduate';
import { asRightArrow } from './svg/rightArrow';
import { asArrow } from './svg/arrow';
import { asMention } from './svg/mention';
import { asMentionVideo } from './svg/mentionVideo';
import { asVideo } from './svg/video';
import { asAddPage } from './svg/addPage';
import { asRemovePage } from './svg/removePage';
import { asRestore } from './svg/restore';
import { asUndo } from './svg/undo';
import { asRedo } from './svg/redo';
import { asSeprerate } from './svg/seprerate';
import { asMsTeam } from './svg/msTeam';

@NgModule({
  imports: [
    CommonModule,
    SvgIconsModule.forRoot({
      sizes: {
        xs: '5px',
        sm: '12px',
        smm: '14px',
        md: '16px',
        mdd: '18px',
        lg: '20px',
        xl: '25px',
        xxl: '30px',
      },
      icons: [
        asChatBubble,
        asMenuCollapse,
        asLogoHall,
        asBell,
        asDot3Hoz,
        asHome,
        asSetting,
        asUseFlag,
        asDone,
        asLight,
        asWarning,
        asCritial,
        asRigSite,
        asLink,
        asDecision,
        asTime,
        asRig,
        asMailbox,
        asHourGlass,
        asOperator,
        asPhone,
        asProject,
        asReadEnvelope,
        asUnReadEnvelope,
        asTime,
        asWell,
        asRegion,
        asRouting,
        asIOneSheet,
        asHierarchy,
        asClose,
        asEdit,
        asAddProject,
        asProtocol,
        asFile,
        asFileVideo,
        asUpLoad,
        asStar,
        asClipBoard,
        asContact,
        asStick,
        asAdmin,
        asUser,
        asReset,
        asHelper,
        asRigSidebar,
        asChecker,
        asPDF,
        asDownload,
        asWellCareLogo,
        asSignOut,
        asAlert,
        asLL,
        asAlerts,
        asNominate,
        noData,
        asPowerBI,
        asSingleUser,
        asField,
        asFlash,
        asOffice,
        asExclamationMark,
        asHelpCenter,
        asGraduate,
        asRightArrow,
        asArrow,
        asMention,
        asMentionVideo,
        asVideo,
        asAddPage,
        asRemovePage,
        asRestore,
        asUndo,
        asRedo,
        asSeprerate,
        asMsTeam
      ],
    }),
  ],
  exports: [SvgIconsModule],
})
export class IconsModule {}
