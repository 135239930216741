import { Injectable } from '@angular/core';
import { Observable, Subject, finalize, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/shared/services/base.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';

@Injectable({
  providedIn: 'root',
})
export class HelpCenterService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  public subjectRedirectToTermsOfUse = new Subject<number>();
  public subjectSetLoadding = new Subject<boolean>();

  public getVideos(): Observable<any[]> {
    const uri = `video`;
    return this.get<any[]>(uri);
  }

  public getVideoDetail(videoId: string): Observable<any> {
    const uri = `video/${videoId}`;
    return this.get<any>(uri);
  }

  public createInteraction(payload: any, userId: string): Observable<any> {
    const uri = `interaction/create`;
    return this.post<any, any>(uri, payload);
  }

  public updateInteraction(
    payload: any,
    interactionId: string
  ): Observable<any> {
    const uri = `interaction/${interactionId}`;
    return this.put<any, any>(uri, payload);
  }

  public deleteInteraction(interactionId: string): Observable<any> {
    const uri = `interaction/${interactionId}`;
    return this.delete<any>(uri);
  }

  public addComment(payload: any): Observable<any> {
    const uri = `comment/create`;
    AppHelper.UtileFunctions.encode64Payload(payload, ['comment'])

    return this.post<any, any>(uri, payload);
  }

  public deleteComment(commentId: string): Observable<any> {
    const uri = `comment/${commentId}`;
    return this.delete<any>(uri);
  }

  public createVideo(payload: FormData): Observable<any> {
    const uri = `video/create`;
    return this.post<any, any>(uri, payload);
  }

  public editVideo(payload: FormData, videoId: string): Observable<any> {
    const uri = `video/${videoId}`;
    return this.put<any, any>(uri, payload);
  }

  public deleteVideo(videoId: string): Observable<any> {
    const uri = `video/${videoId}`;
    return this.delete<any>(uri);
  }

  public sendViewCount(videoId: string): Observable<any> {
    const uri = `video/${videoId}`;
    return this.patch<any, any>(uri, null);
  }

  public sendReportIssue(payload: FormData): Observable<any> {
    const uri = 'mailbox/report-issue';
    return this.post<any, any>(uri, payload);
  }

  // Category
  getCategories(type: string): Observable<any[]> {
    const uri = `category/?query=${type}`;
    return this.get<any[]>(uri).pipe();
  }


  updateIndexChannel(option: any) {
    const uri = `category/change-index`;
    return this.patch<any, any>(uri, option).pipe();
  }

  //  ============= Guide =============
  getChapters(): Observable<any[]> {
    const uri = `chapter`;
    return this.get<any[]>(uri).pipe();
  }

  getChapter(id: string): Observable<any[]> {
    const uri = `chapter/${id}`;
    return this.get<any[]>(uri).pipe();
  }

  updateChapter(id: string, option: any): Observable<any[]> {
    const uri = `chapter/${id}`;
    return this.put<any, any>(uri, option).pipe();
  }

  getCategory(id: string) {
    const uri = `category/${id}`;
    return this.get<any[]>(uri).pipe();
  }

  updateCategory(id: string, option: any) {
    const uri = `category/${id}`;
    return this.put<any, any>(uri, option).pipe();
  }

  publishReleaseNote(id: string) {
    const uri = `category/publish/${id}`;
    return this.put<any, any>(uri, null).pipe();
  }

  createCategory(option: any) {
    const uri = `category/create`;
    return this.post<any, any>(uri, option).pipe();
  }

  deleteCategory(id: string) {
    const uri = `category/${id}`;
    return this.delete<any[]>(uri).pipe();
  }
  //
  createChapter(option: any) {
    const uri = `chapter/create`;
    return this.post<any, any>(uri, option).pipe();
  }
  deleteChapter(id: string) {
    const uri = `chapter/${id}`;
    return this.delete<any[]>(uri).pipe();
  }
  updateIndexCategory(option: any) {
    const uri = `category/change-index`;
    return this.patch<any, any>(uri, option).pipe();
  }
}
