<div class="sidebar" [ngClass]="{ 'd-none': hasRoute('login') }">
  <div
    class="sidebar-item flex flex-grow gap-3"
    *ngFor="let VRO_TAB of VRO_TABs; let i = index"
    (click)="onTab(VRO_TAB.path); activateClass(VRO_TAB)"
    [ngClass]="{ active: VRO_TAB.isActive }"
  >
    <div>
      <svg-icon [key]="VRO_TAB.svg" [size]="VRO_TAB.sizeIcon"></svg-icon>
    </div>
    <div class="sidebar-title" [ngClass]="expandMenu$ ? '' : 'hide-sidebar'">
      {{ VRO_TAB.title }}
    </div>
  </div>
</div>
