<div
  class="notification"
  style="position: relative"
  [ngClass]="{ onlyButton: !isCollapse }"
>
  <div class="header-content">
    <div class="header-content__title" [ngClass]="{ 'd-none': !isCollapse }">
      <span>Recent Alerts</span>
      <span class="description-icon">
        <app-custom-expand-dialog></app-custom-expand-dialog>
      </span>
    </div>
    <div
      class="header-content__feature"
      [ngClass]="[isCollapse ? 'routateShow' : 'routateHide']"
      (click)="emitChangeSize()"
    >
      <svg-icon 
        class="custom-svg-icon cursor-pointer"
        key="arrow"
        size="md"
      ></svg-icon>
    </div>
  </div>

  <div class="filter" [ngClass]="{ 'd-none': !isCollapse }">
    <div class="fitler__option-list">
      <div class="fitler__option-counter">
        Total: {{ interventionLogLength }} of {{ interventionLogTotalLength }}
      </div>
      <div
        *ngIf="chipFilter"
        class="chip-wrapper m-0 d-flex align-items-baseline"
      >
        <div class="me-2">Filtering by:</div>
        <p-chip [label]="chipFilter" styleClass="chip-item"></p-chip>
      </div>
    </div>
  </div>
  <div class="notification__list-wrap" [ngClass]="{ 'd-none': !isCollapse }">
    <div class="notification__list" #infiniteScroll (scroll)="scrollHandler($event)">
      <ng-container
        *ngFor="let interventionLog of interventionLogs$ | async; let i = index"
      >
        <app-nofitication-item
          [interventionLog]="interventionLog"
          [selectedInterventionId]="(interventionLogsActive$ | async)!"
        ></app-nofitication-item>
      </ng-container>
      <div
        class="project__result"
        *ngIf="interventionLogLength === 0"
        style="font-size: 16px; font-weight: 600"
      >
        No result found
      </div>
    </div>
  </div>
  <!-- Loading -->
  <app-loading *ngIf="isCollapse" [isLoading]="(isLoading$ | async)!" [isIconShow]="true" [size]="'50px'"></app-loading>
  <div class="content-progress-bar-notification" *ngIf="(isLoadingLoadMore$ | async)!">
    <span class="loader"></span>
  </div>
</div>
