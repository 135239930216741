import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaticDocumentService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getUrlTemplate(queryValue: string): Observable<any[]> {
    const uri = `static?query=${queryValue}`;
    return this.get<any[]>(uri, {});
  }
}
