import { Directive, ElementRef, HostListener, Input } from '@angular/core';

import { NgControl } from '@angular/forms';

@Directive({ selector: '[NumbersOnly]' })
export class NumbersOnly {
  @Input() allowDecimals: boolean = true;
  @Input() allowSign: boolean = false;
  @Input() decimalSeparator: string = '.';
  @Input() isBlurActive: boolean = true;

  @Input('maxLength')
  maxLength: number = 5;

  @Input('maxDecimalDigit')
  maxDecimalDigit: number = 2;

  previousValue: string = '';

  // --------------------------------------
  //  Regular expressions
  integerUnsigned: string = '^[0-9]*$';
  integerSigned: string = '^-?[0-9]+$';
  decimalUnsigned: string = '^[0-9,]+(.[0-9]+)?$';
  decimalSigned: string = '^-?[0-9]+(.[0-9]+)?$';
  isFirst: boolean = true;

  constructor(private _inputEl: ElementRef, private ngControl: NgControl) {}

  @HostListener('change', ['$event']) onChange(e: any) {
    this.validateValue(this._inputEl.nativeElement.value);
    // if(this.isFirst)
    // {
    //   this.onblur()
    //   this.isFirst = false
    // }
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (
      this._inputEl.nativeElement.value === '.' &&
      this._inputEl.nativeElement.value.length === 1
    ) {
      this._inputEl.nativeElement.value = '0.';
    }

    let commasRemoved = this._inputEl.nativeElement.value.replace(/,/g, '');

    let toInt: number;
    let toLocale: string;
    if (commasRemoved.split('.').length > 1) {
      let decimal = isNaN(parseInt(commasRemoved.split('.')[1]))
        ? ''
        : commasRemoved.split('.')[1];

      if (commasRemoved.length > this.maxLength) {
        commasRemoved = commasRemoved.slice(0, this.maxLength);
      }
      toInt = parseInt(commasRemoved);

      if (isNaN(toInt)) {
        // toLocale = '0.' + decimal.slice(0, this.maxDecimalDigit);
        toLocale = '.' + decimal.slice(0, this.maxDecimalDigit);
      } else {
        toLocale =
          toInt.toLocaleString('en-US', { useGrouping: false }) +
          '.' +
          decimal.slice(0, this.maxDecimalDigit);
      }
    } else {
      if (commasRemoved.length > this.maxLength) {
        commasRemoved = commasRemoved.slice(0, this.maxLength);
      }
      toInt = parseInt(commasRemoved);
      toLocale = toInt.toLocaleString('en-US', { useGrouping: false });
    }

    if (toLocale === 'NaN') {
      this._inputEl.nativeElement.value = '';
    } else {
      this._inputEl.nativeElement.value = toLocale;
    }
  }

  @HostListener('blur')
  onblur() {
    let decimal: string;
    let toInt: number;

    if (!this.isBlurActive) return;

    let commasRemoved = this._inputEl.nativeElement.value.replace(/,/g, '');
    if (!commasRemoved) return;

    const splitDot = commasRemoved.split('.');
    const checkCondition = splitDot[1] && splitDot[1].replace(/0/g, '');

    if ( checkCondition && this.maxDecimalDigit > 0) {
      decimal = commasRemoved.split('.')[1];
      decimal =
        commasRemoved.split('.')[1] +
        ''.padEnd(
          this.maxDecimalDigit - commasRemoved.split('.')[1].length,
          '0'
        );

      toInt = parseInt(commasRemoved);

      this._inputEl.nativeElement.value =
        toInt.toLocaleString('en-US', { useGrouping: true }) + '.' + decimal;

      this.ngControl.control!.setValue(
        toInt.toLocaleString('en-US', { useGrouping: true }) + '.' + decimal
      );
    } else {
      toInt = parseInt(commasRemoved);

      this.ngControl.control!.setValue(
        toInt.toLocaleString('en-US', {
          useGrouping: true,
        })
      );

      if (!this.allowSign && !this.allowDecimals) {
        this._inputEl.nativeElement.value = toInt.toLocaleString('en-US', {
          useGrouping: true,
        });

        this.ngControl.control!.setValue(
          toInt.toLocaleString('en-US', {
            useGrouping: true,
          })
        );
      }
    }
  }

  @HostListener('focus', ['$event'])
  public onFocus(event: any) {
    this._inputEl.nativeElement.value =
      this._inputEl.nativeElement.value.replace(/,/g, '');
  }

  @HostListener('paste', ['$event']) onPaste(e: any) {
    // get and validate data from clipboard
    let value = e.clipboardData.getData('text/plain');
    this.validateValue(value);
    e.preventDefault();
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: any) {
    let cursorPosition: number = e.target['selectionStart'];
    let originalValue: string = e.target['value'];
    let key: string = this.getName(e);
    let controlOrCommand = e.ctrlKey || e.metaKey;
    let signExists = originalValue.includes('-');
    let separatorExists = originalValue.includes(this.decimalSeparator);

    // allowed keys apart from numeric characters
    let allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Escape',
      'Tab',
      'Home',
      'End',
      'Delete',
    ];

    // when decimals are allowed, add
    // decimal separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    let separatorIsCloseToSign = signExists && cursorPosition <= 1;
    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      if (this.decimalSeparator == '.') allowedKeys.push('.');
      else allowedKeys.push(',');
    }

    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    let firstCharacterIsSeparator =
      originalValue.charAt(0) != this.decimalSeparator;
    if (
      this.allowSign &&
      !signExists &&
      firstCharacterIsSeparator &&
      cursorPosition == 0
    ) {
      allowedKeys.push('-');
    }

    // allow some non-numeric characters
    if (
      allowedKeys.indexOf(key) != -1 ||
      // Allow: Ctrl+A and Command+A
      (key == 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key == 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key == 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key == 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    let regex: any;
    if (!this.allowDecimals && !this.allowSign) regex = this.integerUnsigned;
    if (!this.allowDecimals && this.allowSign) regex = this.integerSigned;
    if (this.allowDecimals && !this.allowSign) regex = this.decimalUnsigned;
    if (this.allowDecimals && this.allowSign) regex = this.decimalSigned;

    // allow number characters only
    let isNumber = new RegExp(regex).test(key);
    if (isNumber) return;
    else e.preventDefault();
  }

  validateValue(value: string): void {
    // choose the appropiate regular expression
    let regex: any;
    if (!this.allowDecimals && !this.allowSign) regex = this.integerUnsigned;
    if (!this.allowDecimals && this.allowSign) regex = this.integerSigned;
    if (this.allowDecimals && !this.allowSign) regex = this.decimalUnsigned;
    if (this.allowDecimals && this.allowSign) regex = this.decimalSigned;

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    let firstCharacter = value.charAt(0);
    if (firstCharacter == this.decimalSeparator) value = 0 + value;

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    let lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter == this.decimalSeparator) value = value + 0;

    // test number with regular expression, when
    // number is invalid, replace it with a zero
    let valid: boolean = new RegExp(regex).test(value);
    this._inputEl.nativeElement['value'] = valid ? value : '';
  }

  getName(e: any): any {
    if (e.key) {
      return e.key;
    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8:
            return 'Backspace';
          case 9:
            return 'Tab';
          case 27:
            return 'Escape';
          case 37:
            return 'ArrowLeft';
          case 39:
            return 'ArrowRight';
          case 188:
            return ',';
          case 190:
            return '.';
          case 109:
            return '-'; // minus in numbpad
          case 173:
            return '-'; // minus in alphabet keyboard in firefox
          case 189:
            return '-'; // minus in alphabet keyboard in chrome
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
  }
}
