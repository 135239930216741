<div class="photo-cropper">
  <image-cropper
    [imageFile]="imageFile"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="containWithinAspectRatio"
    [aspectRatio]="aspectRatio"
    [resizeToWidth]="256"
    [cropperMinWidth]="128"
    [onlyScaleDown]="true"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [alignImage]="'center'"
    [style.display]="showCropper ? null : 'none'"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"
    [roundCropper]="roundCropper"
  ></image-cropper>
  <div class="mt-3">
    <p-slider
      [(ngModel)]="scale"
      [min]="1"
      [max]="2"
      [step]="0.000001"
      (onChange)="zoom($event)"
    ></p-slider>
  </div>
  <div class="d-flex justify-content-end my-4 gap-2">
    <p-button
      [attr.data-cy]="'cancel-button'"
      type="button"
      label="Cancel"
      class="custom-p-button custom-p-button--primary"
      (onClick)="onCancel()"
    ></p-button>
    <p-button
      [attr.data-cy]="'crop-button'"
      type="button"
      label="Crop"
      class="custom-p-button custom-p-button--secondary"
      (onClick)="onCrop()"
    ></p-button>
  </div>
</div>
