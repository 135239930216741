import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';

import { AppHelper } from '../utilities/app.helper';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const sasToken = environment.sasToken;

@Injectable({
  providedIn: 'root',
})
export class AzureBlobService {
  accountname = environment.blobAccountName;

  containerName = 'rigimage';

  containerNameXLSX = 'rigdistributionlist';

  containerNameStaticXLSX = 'material';

  containerNamePDFIntervention = 'intervention';

  containerNameEvidence = 'evidences';

  containerNameDataRouting = 'rigdatarouting';

  containerNameProtocal = 'rigcommunicateprotocal';

  containerNameDesignOfService = 'intervaldesignofservice';

  containerUseravatar = 'useravatar';

  containerHelCenterImage = 'thumbnail';

  containerHelpCenterVideo = 'video';

  interventionChatAttachment = 'interventionchatattachment';

  interventionAlertEditor = 'alerteditor';

  constructor() {}
  // =============================================================
  private getContainerClient(containerName: string): ContainerClient {
    return new BlobServiceClient(
      `https://${this.accountname}.blob.core.windows.net${sasToken}`
    ).getContainerClient(containerName);
  }

  private getBlobUrl(blobName: string, containerName: string): string {
    const blobClient =
      this.getContainerClient(containerName).getBlockBlobClient(blobName);
    return blobClient.url;
  }

  public getRigImage(blobName: string): string {
    return this.getBlobUrl(blobName, this.containerName);
  }

  public getUserImage(blobName: string): string {
    return this.getBlobUrl(blobName, this.containerUseravatar);
  }

  public getVideoUrl(videoName: string): string {
    return this.getBlobUrl(videoName, this.containerHelpCenterVideo);
  }

  public getAttachmentUrl(imageName: string): string {
    return this.getBlobUrl(imageName, this.interventionChatAttachment);
  }

  public async removeInterventionAttachment(filename: string) {
    try {
      var blobClient = this.getContainerClient(
        this.interventionChatAttachment
      ).getBlockBlobClient(filename);
      await blobClient.delete();
    } catch (error) {}
  }

  public getVideoThumbnailUrl(imageName: string): string {
    return this.getBlobUrl(imageName, this.containerHelCenterImage);
  }

  private containerClient(sas?: string): ContainerClient {
    let token = sas ? sas : '';

    return new BlobServiceClient(
      `https://${this.accountname}.blob.core.windows.net${sasToken}`
    ).getContainerClient(this.containerName);
  }

  private containerClientXLSX(sas?: string): ContainerClient {
    let token = sas ? sas : '';

    return new BlobServiceClient(
      `https://${this.accountname}.blob.core.windows.net${sasToken}`
    ).getContainerClient(this.containerNameXLSX);
  }

  private containerClientStaticXLSX(sas?: string): ContainerClient {
    let token = sas ? sas : '';

    return new BlobServiceClient(
      `https://${this.accountname}.blob.core.windows.net${sasToken}`
    ).getContainerClient(this.containerNameStaticXLSX);
  }

  private containerClientPDF(
    sas?: string,
    containerName?: string
  ): ContainerClient {
    let token = sas ? sas : '';

    return new BlobServiceClient(
      `https://${this.accountname}.blob.core.windows.net${sasToken}`
    ).getContainerClient(containerName || ''); //this.containerNamePDFIntervention
  }

  private containerClientEvidence(sas?: string): ContainerClient {
    let token = sas ? sas : '';

    return new BlobServiceClient(
      `https://${this.accountname}.blob.core.windows.net${sasToken}`
    ).getContainerClient(this.containerNameEvidence);
  }

  public downloadImage = async (
    name: string,
    handler: (blob: Blob) => void
  ) => {
    try {
      var blobClient = this.containerClient().getBlockBlobClient(name);
      const extensionType = `image/${AppHelper.StringFunctions.getExtension(
        name
      )}`;
      const blobOptions = { blobContentType: extensionType };

      await blobClient.setHTTPHeaders(blobOptions);
      blobClient.download().then((res: any) => {
        res.blobBody?.then((blob: any) => {
          handler(blob);
        });
      });
    } catch (error) {}
  };

  public downloadBlob = async (name: string): Promise<Blob | undefined> => {
    try {
      var blobClient = this.containerClient().getBlockBlobClient(name);
      const extensionType = `image/${AppHelper.StringFunctions.getExtension(
        name
      )}`;
      const blobOptions = { blobContentType: extensionType };

      await blobClient.setHTTPHeaders(blobOptions);
      return (await blobClient.download()).blobBody;
    } catch (error) {}
  };

  public downloadXLSX = async (name: string): Promise<any> => {
    var blobClient = this.containerClientXLSX().getBlockBlobClient(name);
    //

    const extensionType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
    //
    const blobOptions = { blobContentType: extensionType };

    await blobClient.setHTTPHeaders(blobOptions);

    var file = await blobClient.download();
    var resBlob: any = await file.blobBody;

    //
    return resBlob;
  };

  public downloadStatisXLSX = async (name: string): Promise<any> => {
    try {
      var blobClient =
        this.containerClientStaticXLSX().getBlockBlobClient(name);
      //
      const extensionType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
      //
      const blobOptions = { blobContentType: extensionType };

      await blobClient.setHTTPHeaders(blobOptions);

      var file = await blobClient.download();
      var resBlob: any = await file.blobBody;
      //
      return resBlob;
    } catch (error) {
      return error;
    }
  };

  public downloadPDF = async (
    name: string,
    containerName: string
  ): Promise<any> => {
    try {
      var blobClient = this.containerClientPDF(
        '',
        containerName
      ).getBlockBlobClient(name);

      const extensionType = `application/pdf`;
      const blobOptions = { blobContentType: extensionType };

      await blobClient.setHTTPHeaders(blobOptions);

      var file = await blobClient.download();
      var resBlob: any = await file.blobBody;

      return resBlob;
    } catch (error) {
      return error;
    }
  };

  public getAlertEditorUrl = async(
    name: string
  ): Promise<any> => {
    const blobClient = this.containerClientPDF('', this.interventionAlertEditor).getBlockBlobClient(name);

    const extensionType = `application/json`;
    const blobOptions = { blobContentType: extensionType };

    await blobClient.setHTTPHeaders(blobOptions);

    const file = await blobClient.download();
    const resBlob: any = await file.blobBody;

    return resBlob;
  }

  public downloadImageEvidence = async (
    name: string,
  ): Promise<Blob> => {
    return new Promise(async (resolve, reject) => {
      try {
        var blobClient = await this.containerClientEvidence().getBlockBlobClient(
          name
        );
        const extensionType = `image/${AppHelper.StringFunctions.getExtension(
          name
        )}`;
        const blobOptions = { blobContentType: extensionType };

        await blobClient.setHTTPHeaders(blobOptions);
        blobClient.download().then((res: any) => {
          res.blobBody?.then((blob: any) => {
            resolve(blob);
          });
        });
      } catch (error) {}
    })
  }
}
