import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { HelpCenterService } from '../help-center.service';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detail-video',
  templateUrl: './detail-video.component.html',
  styleUrls: ['./detail-video.component.scss'],
})
export class DetailVideoComponent implements OnInit {
  @Input() display: boolean = false;
  @Input() user?: any;
  @Input() video: any;
  @Output() onDestroy: EventEmitter<any> = new EventEmitter();
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;

  @Input() userRelated: any[] = [];
  @ViewChild('content') content!: ElementRef;
  isOpenMentionList: boolean = false;
  userRelatedShow: any[] = [];
  stringSearchUser = '';
  caretFocus: any = {
    indexElementActive: 0,
    index: 0
  };

  userInteraction: {
    interactionId: string;
    interactionType: 'like' | 'dislike' | '';
  } = {
    interactionId: '',
    interactionType: '',
  };
  interactionSummary: { like: number; dislike: number } = {
    like: 0,
    dislike: 0,
  };
  commentList: any[] = [];
  isPlayed: boolean = false;

  avatarMap: Map<string, string> = new Map<string, string>();

  disableButtonVote$ = new BehaviorSubject<boolean>(false);
  loadingCallApi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private _helpCenterService: HelpCenterService,
    private _azureBlobService: AzureBlobService,
    private renderer: Renderer2,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this._helpCenterService.getVideoDetail(this.video.videoId).subscribe({
      next: ({ data }: any) => {
        if (data.interactionOfUser) {
          this.userInteraction.interactionType =
            data.interactionOfUser.interactionType;
          this.userInteraction.interactionId =
            data.interactionOfUser.interactionId;
        }
        this.commentList = data.commentList;
        this.interactionSummary = data.interactionSummary;
        this.prepareAvatarMap();
      },
    });

    this.resetValueInnerHTML();
  }

  public addComment(): void {
    this.removeConsecutiveBr();
    const content = this.getValueInnerHTML();    
    if (this.loadingCallApi$.getValue() || !content) return;
    
    this.loadingCallApi$.next(true);
    
    const listUserIdTagging: string[] = this.getUserTagging() || [];
    this._helpCenterService
      .addComment({
        videoId: this.video.videoId,
        comment: AppHelper.StringFunctions.textTrimming(content),
        listUserIdTagging
      })
      .pipe(
        switchMap(() => {
          this.resetValueInnerHTML();
          return this._helpCenterService.getVideoDetail(this.video.videoId);
        })
      )
      .subscribe({
        next: ({ data }: any) => {
          this.commentList = data.commentList;
          this.loadingCallApi$.next(false);
        },
        error: (_) => {
          this.loadingCallApi$.next(false);
        }
      });
  }

  public getAvatar(blobUrl: string): string {
    if (blobUrl)
      return this._azureBlobService.getUserImage(
        decodeURIComponent(AppHelper.StringFunctions.getFileName(blobUrl))
      );
    return '../../../assets/images/no-avatar.jpg';
  }

  public transformDate(date: string): string {
    return AppHelper.StringFunctions.descriptionDate(date);
  }

  public onMenuSelected(event: any): void {
    if (event.type === 'Delete') {
      this._helpCenterService
        .deleteComment(event.comment.commentId)
        .pipe(
          switchMap(() => {
            return this._helpCenterService.getVideoDetail(this.video.videoId);
          })
        )
        .subscribe({
          next: ({ data }: any) => {
            this.commentList = data.commentList;
          },
        });
    }
  }

  public onPlayVideo(event: any): void {
    if (!this.isPlayed) {
      this.isPlayed = true;
      this._helpCenterService.sendViewCount(this.video.videoId).subscribe();
    }
  }

  setDataForDisableFlag(val: boolean) {
    this.disableButtonVote$.next(val);
  }

  public interact(interactionType: 'like' | 'dislike'): void {
    this.setDataForDisableFlag(true);
    if (this.userInteraction.interactionType === '') {
      this.userInteraction.interactionType = interactionType;
      this.interactionSummary[interactionType] += 1;
      this._helpCenterService
        .createInteraction(
          { videoId: this.video.videoId, interactionType: interactionType },
          this.user.id
        ).pipe(
          tap(_ => this.setDataForDisableFlag(false))
        )
        .subscribe({
          next: (res) =>
            (this.userInteraction.interactionId = res.data.interactionId),
          error: () => {
            this.userInteraction.interactionType = '';
            this.interactionSummary[interactionType] -= 1;
          },
        });
    } else if (this.userInteraction.interactionType === interactionType) {
      this.userInteraction.interactionType = '';
      this.interactionSummary[interactionType] -= 1;
      this._helpCenterService
        .deleteInteraction(this.userInteraction.interactionId)
        .pipe(
          tap(_ => this.setDataForDisableFlag(false))
        )
        .subscribe({
          next: () => (this.userInteraction.interactionId = ''),
          error: () => {
            this.userInteraction.interactionType = interactionType;
            this.interactionSummary[interactionType] += 1;
          },
        });
    } else {
      const currentInteraction = this.userInteraction.interactionType;
      this.userInteraction.interactionType = interactionType;
      this.interactionSummary[interactionType] += 1;
      this.interactionSummary[currentInteraction] -= 1;
      this._helpCenterService
        .updateInteraction(
          { interactionType: interactionType },
          this.userInteraction.interactionId
        )
        .pipe(
          tap(_ => this.setDataForDisableFlag(false))
        )
        .subscribe({
          error: () => {
            this.userInteraction.interactionType = currentInteraction;
            this.interactionSummary[interactionType] -= 1;
            this.interactionSummary[currentInteraction] += 1;
          },
        });
    }
  }

  public onComponentDestroy(): void {
    this.onDestroy.emit(this.isPlayed);
  }

  prepareAvatarMap() {
    for (let i = 0; i < this.commentList.length; i++) {
      let comment = this.commentList[i];
      if (this.avatarMap.get(comment.user.id)) continue;

      if (comment.user.avatar) {
        const loadedImage = this._azureBlobService.getUserImage(
          decodeURIComponent(
            AppHelper.StringFunctions.getFileName(comment.user.avatar)
          )
        );
        this.avatarMap.set(comment.user.id, `${loadedImage}`);
      }
    }
  }
  
  public getAvatarUrl(userId: any) {
    return this.avatarMap.get(userId) || '';
  }


  //////////////////////////////////
  ///                           ///
  ///    AREA FOR COMMENTBOX    ///
  ///                           ///
  //////////////////////////////////

  isDisabledComment(): boolean {
    return (
      Boolean(this.getErrorContent()) ||
      !Boolean(this.getTextContent()) ||
      this.user.role === 'Viewer')
  }

  toggleSuggestion(val: boolean = false): void {
    this.isOpenMentionList = val || !this.isOpenMentionList;

    if (!this.isOpenMentionList) {
      this.stringSearchUser = '';
    }
  }

  searchIndicate(): void {
    this.userRelatedShow = this.userRelated.filter(
      (user: any) => 
        !this.stringSearchUser || user.displayName.toLowerCase().includes(this.stringSearchUser.toLowerCase())
    );   
  }

  addTag(event: any = null): void {
    const {indexElementActive} = this.caretFocus;
    const isAddNodeTail = indexElementActive + 1 == this.content.nativeElement.childNodes.length;
    const spaceCharacter = '&nbsp;';
    const userName = this.renderer.createText(`@${event.value.displayName}`);
    const newNode = this.renderer.createElement('span');

    this.renderer.setAttribute(newNode, 'id', `${event.value.id}`);
    this.renderer.setAttribute(newNode, 'class', this.user.id === event.value.id ? 'mine' : 'another');

    this.renderer.setAttribute(newNode, 'contenteditable', 'false');
    this.renderer.appendChild(newNode, userName);


    const textNode = this.content.nativeElement.childNodes[indexElementActive].textContent;
    const idxSearchString = textNode.indexOf(`@${this.stringSearchUser}`);
    const textRest = textNode.slice(idxSearchString + this.stringSearchUser.length + 1) + spaceCharacter;
    this.content.nativeElement.childNodes[indexElementActive].textContent = textNode.slice(0, idxSearchString);

    // add tag into contentEditAble
    if (isAddNodeTail) {
      this.renderer.appendChild(this.content.nativeElement, newNode);
    } else {
      this.insertNodeBefor(newNode, indexElementActive + 1);
    }
    let newInnerHTML = this.content.nativeElement.innerHTML;
    const outerHtmlNewNode = newNode.outerHTML;

    const position = newInnerHTML.indexOf(outerHtmlNewNode) + outerHtmlNewNode.length;
    newInnerHTML = [newInnerHTML.slice(0, position), textRest, newInnerHTML.slice(position) || spaceCharacter].join('');

    this.content.nativeElement.innerHTML = newInnerHTML;
    this.toggleSuggestion(false);

    const nodeFocus = this.content.nativeElement.childNodes[indexElementActive + 2] || this.content.nativeElement;
    this.focusNode(nodeFocus);
  }

  private focusNode(node: any): void {
    // Set focus to the editable div
    this.content.nativeElement.focus();
    const range = document.createRange();
    range.setStart(node, 0);
    range.collapse(true);

    // Remove all existing selections
    const selection = window.getSelection();
    selection?.removeAllRanges();

    // Add the new range to the selection
    selection?.addRange(range);
    this.content.nativeElement.focus();
  }


  public onKeyUp(event: any): void {
    this.getElementActive(event);

    if (event.key === "Escape") {
      this.toggleSuggestion(false);
      return;
    }

    if (event.key === "Backspace" && this.isOpenMentionList && !this.stringSearchUser) {
      this.toggleSuggestion(false);
      return;
    }

    if (event.key === "Backspace" && this.isOpenMentionList) {
      const position = this.stringSearchUser.length - 1;
      this.stringSearchUser = this.stringSearchUser.slice(0, position < 0 ? 0 : position);
      this.searchIndicate();
      return;
    }

    if (event.key === "Backspace" && this.content.nativeElement.textContent.length < 1) {
      while (this.content.nativeElement.hasChildNodes()) {
        this.content.nativeElement.removeChild(this.content.nativeElement.lastChild);
      }
      return;
    }
  }

  public onKeyPress(event: any): void {
    const validChars = /^[A-Za-z0-9+/=]+$/;
    const specialKey = ['Meta', 'Shift', 'Control', 'Alt', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12']
    const math = (keyCode: string) => keyCode === event.key;

    if (specialKey.some(math)) {
      event.preventDefault();
      return;
    }

    if (event.code === 'Enter' && this.isOpenMentionList) {
      if (this.userRelatedShow[0]) {
        this.addTag({value: this.userRelatedShow[0]});
      } else {
        this.toggleSuggestion(false);
      }
      event.preventDefault();
      return;
    }

    if (
      event.keyCode == 13 &&
      event.code === 'Enter' &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      !this.isOpenMentionList 
    ) {
      this.addComment();
      event.preventDefault();
      return;
    }

    if (event.key === 'Enter') {
      return;
    }
    
    if (event.key === "@" && !this.isOpenMentionList) {
      this.toggleSuggestion(true);
      this.searchIndicate();
      return;
    }
    if (event.key === " " && this.isOpenMentionList) {
      this.stringSearchUser = "";
      this.toggleSuggestion(false);
      return;
    }
    
    if (this.isOpenMentionList && validChars.test(event.key)) {
      this.stringSearchUser += event.key
      this.searchIndicate();
      return;
    }
  }

  getElementActive(event: any) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      this.content.nativeElement.childNodes.forEach((element: any, i: number) => {
        if (element == range.commonAncestorContainer) {
          this.caretFocus = {
            indexElementActive: i,
            index: range.endOffset
          }
        }
      })
    }    
  }

  public getErrorContent(): boolean {
    const result = this.content?.nativeElement?.textContent?.length > 1000 || false;
    return result;
  }

  public getTextContent(): string {
    return this.content?.nativeElement?.textContent || '';
  }

  private resetValueInnerHTML(val: string = '') {
    if (this.content)
      this.content.nativeElement.innerHTML = val;
  }

  private getValueInnerHTML() {
    return this.content?.nativeElement?.innerHTML || '';
  }

  private setStatusComment(value: boolean = true) {
    if (this.content)
      this.content.nativeElement.setAttribute('contenteditable', value.toString());
  }

  private insertNodeBefor(newNode: any, pos: number) {
    this.renderer.insertBefore(
      this.content.nativeElement,
      newNode,  
      this.content.nativeElement.childNodes[pos]
    );
  }

  private removeConsecutiveBr() {
    const div = this.content.nativeElement;
    let prevNode: Node | null = null;

    for (let i = 0; i < div.childNodes.length; i++) {
      const currentNode = div.childNodes[i];
      if (prevNode && prevNode.nodeName === 'BR' && currentNode.nodeName === 'BR') {
        div.removeChild(currentNode);
        i--; // Adjust index since we removed a node
      }
      prevNode = currentNode;
    }
  }

  private getUserTagging(): Array<string> {
    let listId: string[] = [];
    
    this.content.nativeElement.childNodes.forEach((child: any) => {
      const math = (id: any) => id === child.id;
      if (child.nodeName.toLowerCase() == 'span' && !listId.some(math)) {
        listId.push(child.id);
      }
    })

    return listId;
  }

  //////////////////////////////////
  ///                           ///
  ///    AREA FOR COMMENTBOX    ///
  ///                           ///
  //////////////////////////////////
}
